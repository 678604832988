import React from 'react';
import { Check as CheckIcon, People as PeopleIcon, Launch as LaunchIcon, ShoppingBasket as ShoppingBasketIcon, DateRange as DateRangeIcon, Link as LinkIcon, Message as MessageIcon, TrendingUp as TrendingUpIcon, Notifications as NotificationsIcon, Settings as SettingsIcon } from '@mui/icons-material';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { graphql } from 'gatsby';

import { Layout } from '../components/Layout';
import { Hero } from '../components/Hero';

import menuImage from '../images/screenshots/menu.png';
import ordersImage from '../images/screenshots/orders.png';
import reservationsImage from '../images/screenshots/reservations.png';

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        title
        tagline
        description
        social {
          facebook
        }
      }
    }
  }
`;

export { Head } from "../components/Head";

const IndexPage = () => (
  <Layout>
    <Hero />
    <div id="features" className="section features">
      <div className="container">
        <h2 className="title">Features</h2>
        <div className="columns">
          <div className="column">
            <h3 className="title is-5"><LinkIcon /> Receive Orders Online</h3>
            <p className="has-text-justified">Receive orders from your customers directly from your website, facebook page or by sharing link on whatsapp.</p>
          </div>
          <div className="column">
            <h3 className="title is-5"><PeopleIcon /> Own Your Customers</h3>
            <p className="has-text-justified">Have direct access to your customers instead of routing them to third party sites which list you among competitors.</p>
          </div>
          <div className="column">
            <h3 className="title is-5"><TrendingUpIcon /> Increase Net Profit</h3>
            <p className="has-text-justified">Have more direct orders without any third party comissions or fees per order to increase your net profit.</p>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <h3 className="title is-5"><ShoppingBasketIcon /> Orders Management</h3>
            <p className="has-text-justified">Track pending, in-progress, complete and canceled orders wether they are dine in, take away or home delivery.</p>
          </div>
          <div className="column">
            <h3 className="title is-5"><DateRangeIcon /> Reservations Management</h3>
            <p className="has-text-justified">Keep record of upcoming bookings by date/time to send confirmation and reminder messages via whatsapp.</p>
          </div>
          <div className="column">
            <h3 className="title is-5"><MessageIcon /> WhatsApp Integration</h3>
            <p className="has-text-justified">Keep your customer posted when the order gets dispatched for delivery or it becomes ready for take away.</p>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <h3 className="title is-5"><NotificationsIcon /> Realtime Notifcations</h3>
            <p className="has-text-justified">Get realtime alerts for new orders placed online inside the app or via email when you are singed out.</p>
          </div>
          <div className="column">
            <h3 className="title is-5"><SettingsIcon /> Zero Setup Fees</h3>
            <p className="has-text-justified">Instantly start using the solution yourself online without going through complicated processes and setup fees.</p>
          </div>
          <div className="column">
            <h3 className="title is-5"><LaunchIcon /> Adopt Proven Solution</h3>
            <p className="has-text-justified">Use thoughtfully designed solution to get up and running in no time instead of reinventing the wheels.</p>
          </div>
        </div>
        <Slider autoplay={true} dots={true} className="screenshots box">
          <img alt="Menu Management" src={menuImage} />
          <img alt="Orders Management" src={ordersImage} />
          <img alt="Reservations Management" src={reservationsImage} />
        </Slider>
      </div>
    </div>
    <div id="pricing" className="section pricing">
      <div className="container">
        <h2 className="title">Pricing</h2>
        <p className="mb-3">
          Get started for free and pay as you scale up.
        </p>
        <div className="columns">
          <div className="column">
            <div className="box has-background-info-light">
              <h3 className="subtitle">Free</h3>
              <h3 className="title">$0<span className="subtitle has-text-grey"> / month</span></h3>
              <p>Upto 100 orders each month</p>
              <p>Upto 100 reservations each month</p>
            </div>
          </div>
          <div class="column">
            <div className="box has-background-info-light">
              <h3 className="subtitle">Starter</h3>
              <h3 className="title">$10<span className="subtitle has-text-grey"> / month</span></h3>
              <p>Upto 3,000 orders each month</p>
              <p>Upto 3,000 reservations each month</p>
            </div>
          </div>
          <div class="column">
            <div className="box has-background-info-light">
              <h3 className="subtitle">Professional</h3>
              <h3 className="title">$25<span className="subtitle has-text-grey"> / month</span></h3>
              <p>Upto 10,000 orders each month</p>
              <p>Upto 10,000 reservations each month</p>
            </div>
          </div>
          <div class="column">
            <div className="box has-background-info-light">
              <h3 className="subtitle">Advance</h3>
              <h3 className="title">$50<span className="subtitle has-text-grey"> / month</span></h3>
              <p>Upto 100,000 orders each month</p>
              <p>Upto 100,000 reservations each month</p>
            </div>
          </div>
        </div>
        <hr />
        <div className="columns">
          <div className="column"><CheckIcon /> Zero Setup Fees</div>
          <div className="column"><CheckIcon /> Zero Comission Per Order</div>
          <div className="column"><CheckIcon /> Menu Management</div>
        </div>
        <div className="columns">
          <div className="column"><CheckIcon /> Receive Orders Online</div>
          <div className="column"><CheckIcon /> New Order Notification</div>
          <div className="column"><CheckIcon /> WhatsApp Integration</div>
        </div>
        <a href="https://app.servetreats.com/signup" className="getting-started button is-rounded is-medium is-primary">Get Started</a>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
