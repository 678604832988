import React from 'react';

import SocialCover from '../images/social-cover.png';

export const Head = ({ data, pageContext }) => {
  const { site: { siteMetadata } } = data;
  return (
    <>
      <title>{pageContext.title ? `${pageContext.title} - ${siteMetadata.title}` : `${siteMetadata.title} - ${siteMetadata.tagline}`}</title>
      <meta name="description" content={pageContext.description || siteMetadata.description} />
      <meta property="og:url" content={siteMetadata.siteUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={siteMetadata.title} />
      <meta property="og:description" content={siteMetadata.description} />
      <meta property="og:image:url" content={siteMetadata.siteUrl + SocialCover} />
      <meta property="og:image:secure_url" content={siteMetadata.siteUrl + SocialCover} />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="629" />
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'http://schema.org',
          '@type': 'Organization',
          '@id': siteMetadata.siteUrl + '/#org',
          name: siteMetadata.title,
          description: siteMetadata.description,
          url: siteMetadata.siteUrl,
          email: siteMetadata.email,
          logo: siteMetadata.siteUrl + '/icons/icon-512x512.png',
          sameAs: Object.keys(siteMetadata.social).map(k => siteMetadata.social[k])
        }, undefined, 2)}
      </script>
    </>
  );
};
