import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export class Hero extends React.Component {
  componentDidMount() {

  }
  render() {
    return (
      <section className="main-slider">
        <Slider autoplay={true}>
          <div className="slide slide-1">
            <div className="headlines">
              <h3 className="title is-3 has-text-white">Your restaurant brand, your customers.</h3>
              <h5 className="subtitle is-5 has-text-white">Connect with your customers directly, instead of competing on marketplaces for comission-based orders at cheaper rates.</h5>
            </div>
          </div>
          <div className="slide slide-2">
            <div className="headlines">
              <h3 className="title is-3 has-text-white">Receive direct orders online</h3>
              <h5 className="subtitle is-5 has-text-white">Get comission-free, branded ordering system that integrates with your website and facebook page to boost sales and profits.</h5>
            </div>
          </div>
          <div className="slide slide-3">
            <div className="headlines">
              <h3 className="title is-3 has-text-white">Leverage technology to accelerate your restaurant business</h3>
              <h5 className="subtitle is-5 has-text-white">Use our restaurant management software to automate your orders and reservations with digital communication to thrive in internet age.</h5>
            </div>
          </div>
        </Slider>
      </section>
    );
  }
}
